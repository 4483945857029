import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from 'store/actions';
import { UserSelectors } from 'store/selectors';
import ProfileUpdatesView, { ProfileUpdatesViewProps } from './ProfileUpdatesView';
import { RootState } from '../../../store/rootReducer';

export type ProfileUpdatesViewContainerProps = Omit<
  ProfileUpdatesViewProps,
  'isSending' | 'isSuccessfullySubmitted' | 'submittedEmail' | 'onSubmit'
> & {
  onNextStep: () => void;
};

const ProfileUpdatesViewContainer: React.FC<ProfileUpdatesViewContainerProps> = (props) => {
  const { onNextStep, user, ...rest } = props;
  const dispatch = useDispatch();
  const [isUserUpdated, setUserUpdated] = useState(false);

  const isUserUpdating = useSelector((state: RootState) => UserSelectors.isUserUpdating(state, user.id));
  const userIsSuccessfullyUpdated = useSelector((state: RootState) =>
    UserSelectors.userIsSuccessfullyUpdated(state, user.id),
  );

  const handlePartialUpdateUserDetail = useCallback(
    (values: any) => dispatch(UserActions.partialUpdateUser.request({ id: user.id, entity: values })),
    [dispatch, user],
  );

  useEffect(() => {
    if (userIsSuccessfullyUpdated && !isUserUpdated) {
      setUserUpdated(true);
      dispatch(UserActions.partialUpdateUser.reset({ id: user.id }));
      onNextStep();
      return () => {
        dispatch(UserActions.partialUpdateUser.reset({ id: user.id }));
      };
    }
  }, [dispatch, isUserUpdated, onNextStep, user.id, userIsSuccessfullyUpdated]);

  return (
    <ProfileUpdatesView {...rest} user={user} isSending={isUserUpdating} onSubmit={handlePartialUpdateUserDetail} />
  );
};

export default memo(ProfileUpdatesViewContainer);
