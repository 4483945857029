import React, { useEffect, useState } from 'react';
import { Field, Form } from 'formik';
import { useTranslation } from 'i18n';
import { FieldToTextField } from 'components/common/FormField';
import PhoneFieldContainer from 'containers/Field/PhoneFieldContainer';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { findErrorMessage } from 'store/utils';
import withPhoneFieldForm, { FormComponentProps } from './withPhoneFieldForm';

interface StyleProps {
  isFormError: boolean;
}

const useStyles = makeStyles<StyleProps>()((theme, params) => ({
  formFieldWrapper: {
    '&&&': {
      maxWidth: 275,
    },
    '&&& .MuiFormLabel-root': {
      color: '#000',
    },
  },
  phoneField: {
    '.Mui-error': {
      span: {
        top: params.isFormError ? -22 : -34,
        maxWidth: 150,
        textAlign: 'right',
      },
    },
  },
  // continueButton: {
  //   [theme.breakpoints.down('md')]: {
  //     paddingBottom: 16,
  //   },
  // },
}));

const PhoneFieldForm: React.FC<FormComponentProps> = (props) => {
  const { error, errors } = props;
  const [t] = useTranslation('user');
  const { classes } = useStyles({ isFormError: Boolean(errors.phone) });
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (error) {
      try {
        const parsedError = JSON.parse(error.response.responseText);
        setErrorText(findErrorMessage(parsedError));
      } catch (e) {}
    } else setErrorText('');
  }, [error]);

  return (
    <Grid container paddingTop={3} justifyContent="space-between" component={Form}>
      <Grid item xs={12} className={classes.formFieldWrapper}>
        <Field
          className={classes.phoneField}
          label="Phone number"
          fullWidth
          name="phone"
          component={FieldToTextField}
          country="US"
          TextField={PhoneFieldContainer}
          error={error || errors.phone}
          helperText={errorText}
        />
        <Typography variant="body2" color="textSecondary" style={{ paddingTop: 8 }}>
          {t('jLivePassport.phoneVerificationModal.phone.phoneFieldDescription')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withPhoneFieldForm(PhoneFieldForm);
