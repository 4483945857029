import React, { memo, useCallback, useEffect, useState } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'store/actions';
import { AuthSelectors } from 'store/selectors';
import MagicLinkLoginView, { MagicLinkLoginViewProps } from './MagicLinkLoginView';

export type MagicLinkLoginViewContainerProps = Omit<
  MagicLinkLoginViewProps,
  'isSending' | 'isSuccessfullySubmitted' | 'submittedEmail' | 'onSubmit'
>;

const getRedirectTo = (router: NextRouter): string => {
  const { pathname, asPath, query } = router;
  const { redirect_to } = query;

  if (typeof redirect_to === 'string') return redirect_to;
  if (pathname !== '/') return asPath;
  return null;
};

const MagicLinkLoginViewContainer: React.FC<MagicLinkLoginViewContainerProps> = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const sendMagicLinkState = useSelector(AuthSelectors.sendMagicLinkState);
  const [submittedEmail, setSubmittedEmail] = useState('');

  const isSuccessfullySubmitted = sendMagicLinkState.isFulfilled && !sendMagicLinkState.error;
  const isSending = sendMagicLinkState.isPending;
  const redirect_to = getRedirectTo(router);

  const handleSubmit = useCallback(
    (values: any) => {
      setSubmittedEmail(values.email);
      dispatch(
        AuthActions.sendMagicLink.request({
          params: {
            ...values,
            ...(redirect_to && {
              redirect_to,
            }),
          },
        }),
      );
    },
    [dispatch, redirect_to],
  );

  useEffect(() => {
    if (sendMagicLinkState.isFulfilled) {
      return () => {
        dispatch(AuthActions.sendMagicLink.reset());
      };
    }
  }, [dispatch, sendMagicLinkState.isFulfilled]);

  return (
    <MagicLinkLoginView
      {...props}
      isSending={isSending}
      isSuccessfullySubmitted={isSuccessfullySubmitted}
      submittedEmail={submittedEmail}
      onSubmit={handleSubmit}
    />
  );
};

export default memo(MagicLinkLoginViewContainer);
