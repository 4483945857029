import React from 'react';
import { FieldProps } from 'formik';
import { fieldToCheckbox, CheckboxProps } from 'formik-mui';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';

interface IProps<F extends MuiFormControlLabelProps, C extends MuiCheckboxProps> extends FieldProps, CheckboxProps {
  Label: Omit<F, 'checked' | 'name' | 'value' | 'control'>;
  FormControlLabel?: React.ComponentType<F>;
  Checkbox?: React.ComponentType<C>;
}

const CheckboxWithLabel = <F extends MuiFormControlLabelProps, C extends MuiCheckboxProps>({
  FormControlLabel = MuiFormControlLabel,
  Checkbox = MuiCheckbox,
  Label,
  ...props
}: IProps<F, C>) => (
  <FormControlLabel {...(Label as any)} control={<Checkbox {...(fieldToCheckbox(props) as any)} />} />
);

export default CheckboxWithLabel;
