import React from 'react';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { SxProps } from '@mui/system';

interface IProps extends DatePickerProps<any, any> {
  label?: string;
  error?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  timeZone?: string;
  InputComponent: React.ElementType;
  onBlur: () => void;
}

const DatePickerNext: React.FC<IProps> = (props) => {
  const {
    InputComponent,
    label,
    error,
    helperText,
    fullWidth,
    onBlur,
    open,
    onOpen,
    onChange,
    value,
    required,
    ...rest
  } = props;

  const popperSx: SxProps = {
    '& .MuiPickersCalendarHeader-root, .MuiSvgIcon-root': {
      color: '#4E6DF5',
    },
    '& .MuiPickersCalendarHeader-label': {
      fontSize: 16,
      fontWeight: 600,
    },
    '& .MuiPickersDay-root': {
      fontSize: 15,
      fontWeight: 600,
      color: '#383C44',
      borderRadius: '6px',
      ':first-child': {
        color: '#F65675',
      },
    },
    '& .Mui-selected': {
      color: '#fff !important',
      backgroundColor: '#4E6DF5',
    },
  };

  const handleChange = (v: any) => {
    const now = new Date();
    if (!value && v?.$y !== now.getFullYear()) {
      const rv = v.toDate();
      rv.setDate(now.getDate());
      rv.setMonth(now.getMonth());
      return onChange(rv);
    }
    onChange(v?.toDate() || null);
  };

  return (
    <MuiDatePicker
      PopperProps={{
        sx: popperSx,
      }}
      {...rest}
      value={value}
      onChange={handleChange}
      open={open}
      renderInput={(props) => (
        <InputComponent
          {...props}
          open={open}
          onClick={onOpen}
          onBlur={onBlur}
          fullWidth={fullWidth}
          required={required}
          label={label}
          value={value}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default DatePickerNext;
