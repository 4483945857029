import React from 'react';
import { useTranslation } from 'i18n';
import DividerChips from 'components/common/DividerChips';
import AuthLayout from './AuthLayout';
import TextLeftContent from './TextLeftContent';
import BottomNavigation from './BottomNavigation';

export interface InterestViewProps {
  user: any;
  interestsCategories: any[];
  interestsItems: any[];
  onToggle: (id: number) => void;
  onNext: () => void;
}

const InterestView: React.FC<InterestViewProps> = ({ user, interestsCategories, interestsItems, onToggle, onNext }) => {
  const [t] = useTranslation('public');

  return (
    <AuthLayout
      left={
        <TextLeftContent
          title={t('auth.interestView.title')}
          subtitle={t('auth.interestView.subtitle')}
          description={t('auth.interestView.description')}
        />
      }
      content={
        <DividerChips
          categories={interestsCategories}
          items={interestsItems}
          onToggle={onToggle}
          selects={user?.interests}
        />
      }
      actions={<BottomNavigation disabled={user?.interests.length < 3} step={1} onClick={onNext} />}
    />
  );
};

export default InterestView;
