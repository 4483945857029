import React from 'react';
import { Field, Form } from 'formik';
import { Grid, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import { MergedProps } from 'components/common/withFormSubmission';
import { FieldToTextField } from 'components/common/FormField';
import { Trans, useTranslation } from 'i18n';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomTextField from 'components/common/CustomTextField';
import { makeStyles } from 'tss-react/mui';
import withSetPasswordForm, { IProps, IValues } from './withSetPasswordForm';

const useStyles = makeStyles()((theme) => ({
  inputFiled: {
    maxWidth: 270,
    '&& .MuiFormLabel-root': {
      color: `${theme.palette.text.primary} !important`,
      fontSize: '13px !important',
      fontWeight: 600,
    },
  },
  height: {
    height: 400,
  },
  fields: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 85%',
    height: 450,
    maxWidth: 350,
  },
  bottom: {
    flexBasis: 0,
  },
  mainGridContainer: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  title: {
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16 !important',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingTop: '0 !important',
    },
  },
  ph: {
    paddingTop: '0 !important',
    paddingBottom: 16,
  },
}));

const SetPasswordForm: React.FC<MergedProps<IProps, IValues>> = () => {
  const [t, i18n] = useTranslation('public');
  const { classes } = useStyles();

  const [showPasswordOne, setShowPasswordOne] = React.useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = React.useState(false);

  const handleClickShowPasswordOne = () => {
    setShowPasswordOne((show) => !show);
  };

  const handleMouseDownPasswordOne = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPasswordTwo = () => setShowPasswordTwo((show) => !show);

  const handleMouseDownPasswordTwo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const less364 = useMediaQuery('(max-width: 364px)');

  return (
    <Grid container component={Form} className={classes.height} justifyContent="center">
      <Grid item className={classes.fields}>
        <Grid
          className={classes.mainGridContainer}
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
          style={{
            paddingTop: less364 && i18n.language === 'fr' ? 124 : 0,
          }}
        >
          <Grid className={classes.title} item xs={12}>
            <Typography style={{ maxWidth: 324 }} variant="subtitle2">
              {t('modal.setPassword.title')}
            </Typography>
          </Grid>
          <Grid className={classes.ph} item xs={12}>
            <Typography style={{ fontWeight: 500 }} color="textSecondary" variant="body2">
              <Trans i18nKey="modal.resetPassword.description" ns="public">
                <div>Strong passwords include:</div>
                <div>∙ 8+ characters</div>
                <div>∙ at least 1 upper case and lowercase</div>
                <div>∙ at least 1 number</div>
                <div>∙ at least 1 special character</div>
              </Trans>
            </Typography>
          </Grid>
          <Grid style={{ paddingBottom: 16 }} item xs={12}>
            <Field
              className={classes.inputFiled}
              fullWidth
              name="password_one"
              label={t('common:field.label.create_password_one')}
              type={showPasswordOne ? 'text' : 'password'}
              helperTextBottom
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordOne}
                      onMouseDown={handleMouseDownPasswordOne}
                      edge="end"
                      size="small"
                    >
                      {showPasswordOne ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={FieldToTextField}
              TextField={CustomTextField}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: i18n.language === 'fr' ? 64 : 32 }}>
            <Field
              className={classes.inputFiled}
              fullWidth
              name="password_two"
              label={t('common:field.label.create_password_two')}
              type={showPasswordTwo ? 'text' : 'password'}
              helperTextBottom
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordTwo}
                      onMouseDown={handleMouseDownPasswordTwo}
                      edge="end"
                      size="small"
                    >
                      {showPasswordTwo ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={FieldToTextField}
              TextField={CustomTextField}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSetPasswordForm(SetPasswordForm);
