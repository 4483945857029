import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelectors } from 'store/selectors';
import SetPasswordView from './SetPasswordView';
import { AuthActions } from '../../store/actions';

export type UserInfoContainerProps = {
  onClose: () => void;
};

const SetPasswordContainer: React.FC<UserInfoContainerProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { isFulfilled, error, isPending } = useSelector(AuthSelectors.setPasswordState);
  const isSuccessfullySubmitted = isFulfilled && !error;

  useEffect(() => {
    if (isSuccessfullySubmitted) onClose();
  }, [isSuccessfullySubmitted, onClose]);

  useEffect(() => {
    dispatch(AuthActions.resetPassword.reset());
  }, [dispatch]);

  const handleOnSubmit = useCallback(
    (data: any) => {
      dispatch(
        AuthActions.setPassword.request({
          params: {
            password: data.password_one,
            confirm: data.password_two,
          },
        }),
      );
    },
    [dispatch],
  );

  return <SetPasswordView isPending={isPending} onSubmit={handleOnSubmit} onClose={onClose} />;
};

export default memo(SetPasswordContainer);
