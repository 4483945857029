import React, { CSSProperties, memo } from 'react';

const borderRadiusByVariant = {
  'top-left': '100% 0 0 0',
  'top-right': '0 100% 0 0',
  'bottom-right': '0 0 100% 0',
  'bottom-left': '0 0 0 100%',
};

type Props = Pick<
  CSSProperties,
  | 'position'
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'backgroundColor'
  | 'backgroundImage'
  | 'backgroundPosition'
  | 'backgroundSize'
  | 'boxShadow'
  | 'zIndex'
  | 'opacity'
> & {
  radius: number;
  variant: keyof typeof borderRadiusByVariant;
};

const CircleSector: React.FC<Props> = (props) => {
  const {
    radius,
    variant,
    position = 'absolute',
    zIndex,
    opacity,
    top,
    left,
    right,
    bottom,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundSize,
    boxShadow,
    ...other
  } = props;

  const style: CSSProperties = {
    ...other,
    position,
    top,
    left,
    right,
    bottom,
    zIndex,
    opacity,
    width: radius,
    height: radius,
    borderRadius: borderRadiusByVariant[variant],
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundSize,
    boxShadow,
  };

  return <div style={style} {...other} />;
};

export default memo(CircleSector);
