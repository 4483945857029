import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { SerializedError } from 'store/utils';
import { routes } from 'utils/routing';

import { makeStyles } from 'tss-react/mui';
import Loading from './Loading';

export interface RenderComponentProps {
  renderContent: () => React.ReactNode;
  renderError?: () => React.ReactNode;
  layout?: boolean;
  isLoading?: boolean;
  isFetched?: boolean;
  hasPermission?: boolean;
  showError?: boolean;
  errors?: SerializedError[];
  smallLoader?: boolean;
}

const useStyles = makeStyles()(() => ({
  loader: {
    '&.smallLoader': {
      marginTop: 0,
      marginBottom: 0,
      height: 60,
      width: 60,
    },
  },
}));

const RenderComponent: React.FC<RenderComponentProps> = (props) => {
  const {
    layout,
    isLoading,
    isFetched = true,
    errors = [],
    hasPermission = true,
    showError,
    renderContent,
    renderError,
    smallLoader,
  } = props;
  const router = useRouter();
  const { classes, cx } = useStyles();

  useEffect(() => {
    if (errors.length > 0 && !showError && layout) {
      const notFound = errors.some((error) => error.response.status === 404);
      if (notFound) {
        const route = routes.notFound();
        router.replace(route.href, null, { shallow: true });
      }
    }
  }, [errors, layout, router, showError]);

  if (isLoading) {
    return <Loading fullScreen={layout} className={cx(classes.loader, { smallLoader })} />;
  }

  if (errors.length > 0 || showError) {
    return renderError ? renderError() : null;
  }

  if (isFetched && hasPermission) {
    return renderContent();
  }

  return null as any;
};

export default RenderComponent;
