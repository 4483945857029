import React from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Field, Form } from 'formik';
import { useTranslation } from 'i18n';
import { GENDER_OPTIONS, RELATIONSHIP_STATUS_OPTIONS, RELIGION_OPTIONS } from 'components/types/options';
import { FieldToTextField } from 'components/common/FormField';
import FieldToDatePicker from 'components/common/FormField/FieldToDatePicker';
import DatePickerNext from 'components/common/DatePicker/DatePickerNext';
import FieldPreview from 'components/common/FieldPreview';
import withUserInfoForm, { FormComponentProps } from './withUserInfoForm';
import Custom2TextField from '../../common/Custom2TextFiled';

const inputProps = {
  multiple: true,
};

const MenuProps = {
  MenuProps: {
    variant: 'menu',
  },
};

const SelectProps = {
  displayEmpty: true,
};

const InputLabelProps = {
  shrink: true,
};

const notJewish = ['', 'other', 'not_jewish'];

const useStyles = makeStyles()(() => ({
  height: {
    height: '100%',
  },
  fields: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 85%',
  },
  bottom: {
    flexBasis: 0,
  },
  inputLabel: {
    color: 'red !important',
    fontSize: 11,
    fontWeight: 600,
  },
}));

const UserInfoForm: React.FC<FormComponentProps> = (props) => {
  const { jewishDescendants, values, user } = props;
  const { classes, cx } = useStyles();
  const [t] = useTranslation('user');

  return (
    <Grid container direction="column" wrap="nowrap" component={Form} className={classes.height}>
      <Grid item className={cx(classes.height, classes.fields)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Field
              label
              required
              placeholder={t('common:field.label.gender')}
              fullWidth
              name="gender"
              component={FieldToTextField}
              TextField={TextField}
              select
              SelectProps={SelectProps}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value="">{t('common:general.gender')}</MenuItem>
              {GENDER_OPTIONS.map((gender: string) => (
                <MenuItem key={gender} value={gender}>
                  {t('common:general.gender', { context: gender })}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              className={classes.inputLabel}
              autoOk
              fullWidth
              required
              label
              name="birth_date"
              component={FieldToDatePicker}
              DatePicker={DatePickerNext}
              InputComponent={Custom2TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              label={t('common:field.label.postal_zip_code')}
              required
              fullWidth
              id="postal_code"
              placeholder={t('common:field.placeholder.postal_zip_code')}
              autoComplete="postal-code"
              name="postal_code"
              variant="outlined"
              context={user?.address?.country}
              component={FieldToTextField}
              TextField={Custom2TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              label
              fullWidth
              name="religion"
              component={FieldToTextField}
              TextField={TextField}
              select
              SelectProps={SelectProps}
              InputLabelProps={InputLabelProps}
            >
              {RELIGION_OPTIONS.map((religion: string) => (
                <MenuItem key={religion} value={religion}>
                  {t('common:general.religion', { context: religion })}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          {!notJewish.includes(values.religion) && (
            <Grid item xs={12}>
              <Field
                label={t('userProfile.userDemographicsSection.label.jewishDescendance')}
                fullWidth
                name="jewish_descendants"
                component={FieldToTextField}
                TextField={FieldPreview}
                multiple
                select
                inputProps={inputProps}
                SelectProps={MenuProps}
                previewText={values.jewish_descendants.map(({ title }: any) => title).join(', ') || '—'}
              >
                {jewishDescendants.map(({ id, title }) => (
                  <MenuItem value={id}>{title}</MenuItem>
                ))}
              </Field>
            </Grid>
          )}
          <Grid item xs={12}>
            <Field
              label
              fullWidth
              name="relationship_status"
              component={FieldToTextField}
              TextField={TextField}
              select
              SelectProps={SelectProps}
              InputLabelProps={InputLabelProps}
            >
              {RELATIONSHIP_STATUS_OPTIONS.sort().map((status: string) => (
                <MenuItem key={status} value={status}>
                  {t('common:general.relationshipStatus', { context: status })}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withUserInfoForm(UserInfoForm);
