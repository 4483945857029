import React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { Field, Form } from 'formik';
import { useTranslation } from 'i18n';
import { CheckboxWithLabel } from 'formik-mui';

import { FieldToTextField } from 'components/common/FormField';
import Agreement from 'components/common/Agreement';
import MarketsField from 'components/fields/MarketsField';
import Button from 'components/common/Button';
import { makeStyles } from 'tss-react/mui';
import withJoinForm, { FormComponentProps } from './withJoinForm';

const agreementProps = {
  tOptions: {
    context: 'age',
  },
};

const useStyles = makeStyles()(() => ({
  market: {
    '.MuiInputBase-formControl': {
      height: 40,
      color: '#ACADB0',
    },
  },
  marketSelected: {
    '.MuiInputBase-formControl': {
      height: 40,
    },
  },
}));

const JoinForm: React.FC<FormComponentProps> = (props) => {
  const { user, isSubmitting, values, withButton = true, disabledEmail = true } = props;
  const [t] = useTranslation('public');
  const { classes } = useStyles();

  return (
    <Grid container spacing={3} component={Form}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="email"
          variant="outlined"
          placeholder={`${t('common:field.label.email')}*`}
          disabled={Boolean(user?.email) && disabledEmail}
          component={FieldToTextField}
          TextField={TextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          name="first_name"
          variant="outlined"
          placeholder={`${t('common:field.label.first_name')}*`}
          component={FieldToTextField}
          TextField={TextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          name="last_name"
          variant="outlined"
          placeholder={`${t('common:field.label.last_name')}*`}
          component={FieldToTextField}
          TextField={TextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MarketsField
              className={
                !values.primary_market && values.primary_market !== 0 ? classes.market : classes.marketSelected
              }
              onlyLocal
              fullWidth
              name="primary_market"
              variant="outlined"
              placeholder={`${t('common:field.placeholder.primary_city')}*`}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={CheckboxWithLabel}
              name="age"
              type="checkbox"
              color="primary"
              Label={{
                label: <Agreement {...agreementProps} />,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {withButton && (
        <Grid item xs={12}>
          <Box mt={2} textAlign="center">
            <Button
              style={{ height: 40 }}
              rounded
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting || !values.age}
            >
              {t('auth.joinForm.submit')}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default withJoinForm(JoinForm);
