import React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { Field, Form } from 'formik';
import { useTranslation } from 'i18n';
import { FieldToTextField } from 'components/common/FormField';
import Button from 'components/common/Button';
import withResetPasswordForm, { FormComponentProps } from './withResetPasswordForm';

const ResetPasswordForm: React.FC<FormComponentProps> = (props) => {
  const { isSubmitting } = props;
  const [t] = useTranslation('public');

  return (
    <Grid style={{ paddingTop: 12 }} container spacing={3} component={Form}>
      <Grid item xs={12}>
        <Field
          fullWidth
          variant="outlined"
          name="email"
          autoComplete="email"
          placeholder={t('common:field.placeholder.emailShort')}
          component={FieldToTextField}
          TextField={TextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} textAlign="center">
          <Button rounded type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            {t('auth.resetPasswordForm.sendResetPassword')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withResetPasswordForm(ResetPasswordForm);
