import React from 'react';
import { Field, Form } from 'formik';
import { useTranslation } from 'i18n';
import { FieldToTextField } from 'components/common/FormField';
import { Grid, MenuItem, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CustomTextField from 'components/common/CustomTextField';
import FieldToDatePicker from 'components/common/FormField/FieldToDatePicker';
import DatePickerNext from 'components/common/DatePicker/DatePickerNext';
import { GENDER_OPTIONS } from 'components/types/options';
import withUpdateProfileForm, { FormComponentProps } from './withUpdateProfileForm';

const SelectProps = {
  displayEmpty: true,
};

const useStyles = makeStyles()((theme) => ({
  formFieldWrapper: {
    '&&&': {
      maxWidth: 275,
    },
    '&&& .MuiFormLabel-root': {
      color: '#000',
    },
  },
  inputLabel: {
    color: '#7B8BAD !important',
    fontSize: 11,
    fontWeight: 600,
  },
  continueButton: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
    },
  },
}));

const UpdateProfileForm: React.FC<FormComponentProps> = (props) => {
  const { user } = props;
  const [t] = useTranslation('user');
  const { classes } = useStyles();

  return (
    <Grid container paddingTop={3} justifyContent="space-between" style={{ height: '100%' }} component={Form}>
      <Grid item xs={12} className={classes.formFieldWrapper}>
        {/* <Typography variant="body2" color="textSecondary" style={{ paddingTop: 8, marginBottom: 32 }}> */}
        {/*  {t('jLivePassport.phoneVerificationModal.userFields.updateProfileDescription')} */}
        {/* </Typography> */}
        <Grid container spacing={4}>
          {!user?.address?.postal_code && (
            <Grid item xs={12}>
              <Field
                label={t('common:field.label.postal_zip_code')}
                required
                id="postal_code"
                placeholder={t('common:field.placeholder.postal_zip_code')}
                autoComplete="postal-code"
                name="postal_code"
                variant="outlined"
                context={user?.address?.country}
                component={FieldToTextField}
                TextField={CustomTextField}
              />
            </Grid>
          )}
          {!user?.birth_date && (
            <Grid item xs={12}>
              <Field
                fullWidth
                required
                autoOk
                label
                name="birth_date"
                component={FieldToDatePicker}
                DatePicker={DatePickerNext}
                InputComponent={CustomTextField}
              />
            </Grid>
          )}
          {!user?.gender && (
            <Grid item xs={12}>
              <Field
                label
                required
                placeholder
                fullWidth
                name="gender"
                component={FieldToTextField}
                TextField={TextField}
                select
                SelectProps={SelectProps}
                InputLabelProps={{ className: classes.inputLabel, shrink: true }}
              >
                <MenuItem value="">{t('common:general.gender')}</MenuItem>
                {GENDER_OPTIONS.map((gender: string) => (
                  <MenuItem key={gender} value={gender}>
                    {t('common:general.gender', { context: gender })}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withUpdateProfileForm(UpdateProfileForm);
