import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'store/actions';
import { AuthSelectors } from 'store/selectors';
import PhoneVerificationView, { PhoneVerificationViewProps } from './PhoneVerificationView';

export type PhoneVerificationViewContainerProps = Omit<
  PhoneVerificationViewProps,
  | 'isSendingGetVerificationCode'
  | 'isSuccessfullySubmittedGetVerificationCode'
  | 'onSubmitGetVerificationCode'
  | 'isSendingCheckVerificationCode'
  | 'onSubmitCheckVerificationCode'
  | 'resetPhone'
  | 'resend'
> & {
  onNextStep: () => void;
};

const PhoneVerificationViewContainer: React.FC<PhoneVerificationViewContainerProps> = (props) => {
  const { onNextStep, ...rest } = props;
  const dispatch = useDispatch();
  const getVerificationCodeState = useSelector(AuthSelectors.getVerificationCodeState);
  const isSuccessfullySubmittedGetVerificationCode =
    getVerificationCodeState.isFulfilled && !getVerificationCodeState.error;
  const isSendingGetVerificationCode = getVerificationCodeState.isPending;
  const [getVerificationCodeValues, setGetVerificationCodeValues] = useState({});
  const [isVerifingPhone, setVerifyPhone] = useState(false);

  const handleSubmitGetVerificationCode = useCallback(
    (values: any) => {
      setGetVerificationCodeValues(values);
      dispatch(AuthActions.getVerificationCode.reset());
      dispatch(AuthActions.getVerificationCode.request({ params: { ...values } }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (isSuccessfullySubmittedGetVerificationCode) {
      return () => {
        dispatch(AuthActions.getVerificationCode.reset());
        dispatch(AuthActions.checkVerificationCode.reset());
      };
    }
  }, [dispatch, getVerificationCodeState.isFulfilled, isSuccessfullySubmittedGetVerificationCode]);

  const checkVerificationCodeState = useSelector(AuthSelectors.checkVerificationCodeState);
  const isSuccessfullySubmittedCheckVerificationCode =
    checkVerificationCodeState.isFulfilled && !checkVerificationCodeState.error;
  const isSendingCheckVerificationCode = checkVerificationCodeState.isPending;

  const handleSubmitCheckVerificationCode = useCallback(
    (values: any) =>
      dispatch(
        AuthActions.checkVerificationCode.request({
          params: { ...values, ...(getVerificationCodeState.result as any) },
        }),
      ),
    [dispatch, getVerificationCodeState.result],
  );

  const handleResend = useCallback(() => dispatch(AuthActions.getVerificationCode.reset()), [dispatch]);

  useEffect(() => {
    if (isSuccessfullySubmittedCheckVerificationCode) {
      return () => {
        dispatch(AuthActions.getVerificationCode.reset());
        dispatch(AuthActions.checkVerificationCode.reset());
      };
    }
  }, [dispatch, checkVerificationCodeState.isFulfilled, isSuccessfullySubmittedCheckVerificationCode]);

  useEffect(() => {
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (isSuccessfullySubmittedCheckVerificationCode || getVerificationCodeState.result?.status === 'approved') &&
      !isVerifingPhone
    ) {
      setVerifyPhone(true);
      onNextStep();
      dispatch(AuthActions.checkVerificationCode.reset());
    }
  }, [
    isVerifingPhone,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getVerificationCodeState.result?.status,
    isSuccessfullySubmittedCheckVerificationCode,
    onNextStep,
    dispatch,
  ]);

  return (
    <PhoneVerificationView
      {...rest}
      isSendingGetVerificationCode={isSendingGetVerificationCode}
      isSuccessfullySubmittedGetVerificationCode={isSuccessfullySubmittedGetVerificationCode}
      isSendingCheckVerificationCode={isSendingCheckVerificationCode}
      onSubmitGetVerificationCode={handleSubmitGetVerificationCode}
      onSubmitCheckVerificationCode={handleSubmitCheckVerificationCode}
      resetPhone={handleResend}
      resend={() => handleSubmitGetVerificationCode(getVerificationCodeValues)}
      error={getVerificationCodeState.error || checkVerificationCodeState.error}
    />
  );
};

export default memo(PhoneVerificationViewContainer);
