import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  onClose: () => void;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    color: 'white',
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  },
}));

const CloseButton: React.FC<IProps> = (props) => {
  const { onClose } = props;
  const { classes } = useStyles(undefined, {
    props,
  });
  return (
    <IconButton size="small" className={classes.root} onClick={onClose}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );
};

export default CloseButton;
