import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type Props = TextFieldProps & {
  hintText?: string;
  value?: string;
  maxLength: number;
  doShrink?: boolean;
  helperTextBottom?: boolean;
};

const SelectProps = {
  MenuProps: {
    autoFocus: false,
  },
};

interface StyleProps {
  helperTextBottom: boolean;
}

const useStyles = makeStyles<StyleProps>()((_, params) => ({
  helperText: {
    position: 'absolute',
    ...(params.helperTextBottom ? {} : { top: -18 }),
    right: 0,
  },
  inputLabel: {
    width: 'auto !important',
    lineHeight: '100% !important',
  },
}));

const useFormHelperTextStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0.5, 0, 0, 2),
    '&:last-child': {
      flexShrink: 0,
    },
  },
}));

const Custom2TextField: React.FC<Props> = (props) => {
  const {
    value,
    maxLength,
    inputProps,
    FormHelperTextProps,
    helperText,
    hintText = '',
    placeholder,
    select,
    doShrink = true,
    helperTextBottom = false,
    error,
    ...rest
  } = props;
  const { classes: formHelperTextClasses } = useFormHelperTextStyles(undefined, {
    props: FormHelperTextProps,
  });
  const { classes } = useStyles({ helperTextBottom });
  return (
    <TextField
      {...rest}
      select={select}
      value={value}
      inputProps={{ ...inputProps, maxLength }}
      InputLabelProps={{ className: classes.inputLabel, shrink: select ? doShrink : true }}
      FormHelperTextProps={{ ...FormHelperTextProps, classes: formHelperTextClasses }}
      placeholder={hintText || placeholder}
      helperText={<span className={classes.helperText}>{helperText}</span>}
      error={error || Boolean(helperText)}
      sx={{
        '& legend': { display: 'none' },
        '& .MuiOutlinedInput-notchedOutline': {
          top: '0 !important',
        },
      }}
      SelectProps={SelectProps}
    />
  );
};

export default Custom2TextField;
