import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'store/actions';
import { AuthSelectors } from 'store/selectors';
import ResetPasswordView, { ResetPasswordViewProps } from './ResetPasswordView';

export type ResetPasswordViewContainerProps = Omit<
  ResetPasswordViewProps,
  'isSending' | 'isSuccessfullySubmitted' | 'submittedEmail' | 'onSubmit'
>;

const ResetPasswordViewContainer: React.FC<ResetPasswordViewContainerProps> = (props) => {
  const dispatch = useDispatch();
  const sendResetPasswordState = useSelector(AuthSelectors.sendResetPasswordState);
  const [submittedEmail, setSubmittedEmail] = useState('');

  const isSuccessfullySubmitted = sendResetPasswordState.isFulfilled && !sendResetPasswordState.error;
  const isSending = sendResetPasswordState.isPending;

  const handleSubmit = useCallback(
    (values: any) => {
      setSubmittedEmail(values.email);
      dispatch(
        AuthActions.sendResetPassword.request({
          params: {
            ...values,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (sendResetPasswordState.isFulfilled) {
      return () => {
        dispatch(AuthActions.sendResetPassword.reset());
      };
    }
  }, [dispatch, sendResetPasswordState.isFulfilled]);

  return (
    <ResetPasswordView
      {...props}
      isSending={isSending}
      isSuccessfullySubmitted={isSuccessfullySubmitted}
      submittedEmail={submittedEmail}
      onSubmit={handleSubmit}
    />
  );
};

export default memo(ResetPasswordViewContainer);
