import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'store/actions';
import { AuthSelectors } from 'store/selectors';
import { useRouter } from 'next/router';
import LoginView, { LoginViewProps } from './LoginView';
import { routes } from '../../utils/routing';
import { findErrorMessage } from '../../store/utils';
import { useModal } from '../../hooks';
import AuthErrorModal from './AuthErrorModal';
import MfaCodeModal from './MfaCodeModal/MfaCodeModal';

export type LoginViewContainerProps = Omit<
  LoginViewProps,
  'isSending' | 'isSuccessfullySubmitted' | 'submittedEmail' | 'onSubmit'
>;

const MFA_REQUIRED = 'mfa_required';

interface LoginProps {
  email: string;
  password: string;
}

const DONT_REDIRECT_PATHS = ['/events', '/organizations', '/c/', '/e/'];

const LoginViewContainer: React.FC<LoginViewContainerProps> = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const router = useRouter();
  const { replace } = router;
  const { error, isFulfilled, isPending } = useSelector(AuthSelectors.loginState);
  const isSuccessfullySubmitted = isFulfilled && !error;
  const [showErrorModal] = useModal(AuthErrorModal);
  const [showMfaCodeModal] = useModal(MfaCodeModal);
  const [errorMessage, setErrorMessage] = useState('');
  const [loginProps, setLoginProps] = useState<LoginProps>();

  const isSending = isPending;

  const handleSubmit = useCallback(
    (values: any) => {
      setErrorMessage('');
      setLoginProps(values);
      dispatch(
        AuthActions.login.request({
          params: {
            ...values,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (error) {
      const redirect = () => {
        if (!DONT_REDIRECT_PATHS.some((path: string) => router.pathname.includes(path))) {
          const route = routes.home();
          replace(route.href, null, { shallow: true });
        }
      };

      try {
        const parsedError = JSON.parse(error.response.responseText);
        const isMfaRequiredError = parsedError.code === MFA_REQUIRED;

        if (isMfaRequiredError) {
          onClose();
          showMfaCodeModal({ email: loginProps.email, password: loginProps.password });
        } else {
          const displayedError = findErrorMessage(parsedError);
          showErrorModal({
            error: displayedError,
            onExited: redirect,
            onClose: () => dispatch(AuthActions.login.reset()),
          });
        }
      } catch (e) {}
    }
  }, [showErrorModal, showMfaCodeModal, error, replace, dispatch, onClose, loginProps, router.pathname]);

  useEffect(() => {
    if (isFulfilled) {
      if (error)
        try {
          setErrorMessage(JSON.parse(error?.response?.responseText)?.detail);
        } catch (e) {}
      return () => {
        dispatch(AuthActions.login.reset());
      };
    }
  }, [dispatch, error, isFulfilled]);

  return (
    <LoginView
      {...props}
      onClose={onClose}
      isSuccessfullySubmitted={isSuccessfullySubmitted}
      errorMessage={errorMessage}
      isSending={isSending}
      onSubmit={handleSubmit}
    />
  );
};

export default memo(LoginViewContainer);
