import React from 'react';
import { PaperProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
    maxHeight: '740px !important',
    padding: theme.spacing(6.25, 4),
    margin: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: 'unset !important',
      padding: 0,
      margin: 0,
    },
  },
}));

const PaperComponent: React.FC<PaperProps> = (props) => {
  const { className, ...rest } = props;
  const { classes, cx } = useStyles(undefined, {
    props,
  });
  return <div className={cx(classes.root, className)} {...(rest as any)} />;
};

export default PaperComponent;
