import React, { forwardRef, memo } from 'react';
import { OutlinedTextFieldProps, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

export type PhoneFieldProps = Omit<OutlinedTextFieldProps, 'variant'> &
  Pick<PhoneInputProps, 'country' | 'onlyCountries' | 'localization' | 'disableDropdown'> & {
    country: string;
  };

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles<void, 'input' | 'error' | 'disabled' | 'button'>()((theme, _params, classes) => ({
  input: {
    '&.fullWidth': {
      width: '100%',
    },
  },
  helperText: {
    position: 'absolute',
    top: -18,
    right: 0,
  },
  error: {},
  disabled: {},
  button: {
    '& .selected-flag': {
      borderRadius: 'inherit',
      '&: hover': {
        borderRadius: 'inherit',
      },
    },
  },
  container: {
    '&.fullwidth': {
      width: '100%',
    },
    [`& .${classes.input}`]: {
      position: 'absolute !important',
      top: 0,
      left: 0,
      border: `1px solid ${theme.palette.custom.border.gray}!important`,
      borderRadius: '8px !important',
      padding: theme.spacing(1, 2, 1, 7),
      height: '40px !important',
      width: '100% !important',
      [`&.${classes.error}`]: {
        borderColor: theme.palette.error.main,
      },
      [`&.${classes.disabled}`]: {
        borderColor: 'rgba(0, 0, 0, 0.026)',
        cursor: 'default',
        color: theme.palette.text.secondary,
      },
    },
    [`& .${classes.button}`]: {
      left: 0,
      border: `1px solid ${theme.palette.custom.border.gray} !important`,
      borderRadius: '8px 0 0 8px !important',
      background: theme.palette.background.default,
      height: 'unset',
      [`&.${classes.error}`]: {
        borderColor: theme.palette.error.main,
        borderRightColor: theme.palette.custom.border.gray,
      },
      [`&.${classes.disabled}`]: {
        borderColor: 'rgba(0, 0, 0, 0.026)',
        background: theme.palette.background.default,
        cursor: 'default',
        color: theme.palette.text.secondary,
        '& .selected-flag:focus': {
          background: theme.palette.background.default,
        },
        '& .selected-flag:hover': {
          background: theme.palette.background.default,
        },
      },
      '&.open': {
        borderRadius: '8px 0 0 8px !important',
        '& .selected-flag': {
          borderRadius: 'inherit !important',
        },
        '& .country': {
          lineHeight: 1.121,
        },
      },
    },
  },
}));

const useLabelStyles = makeStyles()((theme) => ({
  root: {
    overflow: 'visible !important',
  },
  outlined: {
    transform: 'translate(0px, -15px) scale(0.75) !important',
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));

/* eslint-disable react/jsx-no-duplicate-props */
const PhoneField = forwardRef<any, PhoneFieldProps>((props, ref) => {
  const {
    autoComplete,
    disabled,
    disableDropdown,
    fullWidth,
    country,
    onlyCountries,
    localization,
    InputLabelProps,
    InputProps,
    inputProps,
    helperText,
  } = props;

  const { classes, cx } = useStyles(undefined, {
    props,
  });
  const { classes: labelClasses } = useLabelStyles(undefined, {
    props: InputLabelProps,
  });

  return (
    <TextField
      ref={ref}
      fullWidth={fullWidth}
      disabled={disabled}
      {...props}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: false,
        ...{ classes: labelClasses },
      }}
      InputProps={{
        ...InputProps,
        inputComponent: PhoneInput as any,
      }}
      inputProps={{
        inputProps: {
          ...inputProps,
          ...(autoComplete &&
            autoComplete !== 'off' && {
              autocomplete: autoComplete,
            }),
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        containerClass: cx(classes.container, { fullWidth }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputClass: cx(classes.input, { [classes.disabled]: disabled, fullWidth }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        buttonClass: cx(classes.button, {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          [classes.disabled]: disabled || disableDropdown,
        }),
        country: country?.toLowerCase(),
        disableDropdown,
        onlyCountries,
        localization,
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      helperText={<span className={classes.helperText}>{helperText}</span>}
    />
  );
});

export default memo(PhoneField);
