import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import Button from 'components/common/Button';

const useStyles = makeStyles()((theme) => ({
  box: {
    // minHeight: 120,
  },
  wrapper: {
    zIndex: 1,
    padding: '16px 40px 40px 40px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: '0px -4px 8px rgba(60, 85, 165, 0.16)',
    background: 'white',
    [theme.breakpoints.up('md')]: {
      position: 'static',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
}));

interface BottomNavigationProps {
  disabled?: boolean;
  step: number;
  onClick?: () => void;
  onClose?: () => void;
}

const totalSteps = 3;

const BottomNavigation: React.FC<BottomNavigationProps> = (props) => {
  const { disabled, step, onClick, onClose } = props;
  const { classes } = useStyles();
  const [t] = useTranslation();

  return (
    <Box className={classes.box}>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.wrapper}>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            {t('common:general.step', { active: step, total: totalSteps })}
          </Typography>
        </Grid>
        {totalSteps === step ? (
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button style={{ height: 40 }} rounded color="primary" variant="outlined" onClick={onClose}>
                  {t('common:button.maybeLater')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{ height: 40, minWidth: 97 }}
                  rounded
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={onClick}
                  disabled={disabled}
                >
                  {t('common:button.set')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Button
              style={{ height: 40 }}
              rounded
              type="submit"
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={onClick}
            >
              {t('common:button.continue')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default BottomNavigation;
