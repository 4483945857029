import React from 'react';
import { Fade, Grow } from '@mui/material';
import { useTranslation } from 'i18n';
import CircleSector from 'components/common/CircleSector';
import palette from 'theme/palette';
import { makeStyles } from 'tss-react/mui';
import AuthLayout from './AuthLayout';
import AuthSwitch from './AuthSwitch';
import AuthContent from './AuthContent';
import AuthSubmitContent from './AuthSubmitContent';
import ImageTile from './ImageTile';
import TextTile from './TextTile';
import JoinForm from './JoinForm';

export interface JoinViewProps {
  user?: any;
  isSending: boolean;
  isSuccessfullySubmitted: boolean;
  isAuthenticated: boolean;
  submittedEmail: string;
  onSwitch: () => void;
  onSubmit: (values: any) => void;
  showSwitchButton: boolean;
}

const useStyles = makeStyles()(() => ({
  switchButton: {
    borderRadius: 32,
    width: 121,
  },
}));

const JoinView: React.FC<JoinViewProps> = (props) => {
  const {
    user,
    isSending,
    isSuccessfullySubmitted,
    isAuthenticated,
    submittedEmail,
    onSwitch,
    onSubmit,
    showSwitchButton,
  } = props;
  const [t] = useTranslation('public');
  const { classes } = useStyles();
  const hasForm = !isSuccessfullySubmitted || isAuthenticated;

  return (
    <AuthLayout
      content={
        <div>
          <Grow in={!hasForm} mountOnEnter>
            <div>
              <AuthSubmitContent variant="login" email={submittedEmail} />
            </div>
          </Grow>
          {hasForm && (
            <AuthContent
              title={t('auth.joinView.title')}
              description={t('auth.joinView.description')}
              form={<JoinForm user={user} isSending={isSending || (!user && isAuthenticated)} onSubmit={onSubmit} />}
            />
          )}
        </div>
      }
      switcher={
        <Fade in={hasForm && showSwitchButton} appear={false}>
          <div>
            <AuthSwitch
              label={t('auth.joinView.switch.label')}
              ButtonProps={{
                className: classes.switchButton,
                onClick: onSwitch,
                color: 'primary',
                children: t('auth.joinView.switch.button'),
              }}
            />
          </div>
        </Fade>
      }
      images={
        <>
          <TextTile logo color="secondary" />
          <ImageTile src="/static/img/annie-spratt-WS2anBsrum0.jpeg">
            <CircleSector
              variant="bottom-right"
              position="absolute"
              backgroundColor={palette.custom.orange.main}
              radius={52}
              top={20}
              left={20}
            />
            <CircleSector
              variant="top-right"
              position="absolute"
              backgroundColor={palette.custom.green.main}
              radius={42}
              bottom={16}
              right={36}
            />
          </ImageTile>
          <ImageTile src="/static/img/tijs-van-leur-So6YckShOVA.jpeg">
            <CircleSector
              variant="top-left"
              position="absolute"
              backgroundColor={palette.custom.purple.main}
              radius={45}
              top={30}
              right={70}
            />
          </ImageTile>
          <TextTile text={t('auth.joinView.text')} color="primary" />
        </>
      }
    />
  );
};

export default JoinView;
