import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelectors, OptionsSelectors, UserSelectors } from 'store/selectors';
import { makeEntitiesLoader } from 'hooks/makeSimpleLoader';
import { OptionsActions, UserActions } from 'store/actions';
import { RootState } from 'store/rootReducer';
import UserInfoView from './UserInfoView';

export type UserInfoContainerProps = {
  onNext: () => void;
};

const useJewishDescendantsState = makeEntitiesLoader(
  OptionsActions.getJewishDescendants,
  OptionsSelectors.jewishDescendantsState,
);

const UserInfoContainer: React.FC<UserInfoContainerProps> = ({ onNext }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(AuthSelectors.currentUser);
  const userIsSuccessfullyUpdated = useSelector((state: RootState) =>
    UserSelectors.userIsSuccessfullyUpdated(state, currentUser.id),
  );

  const jewishDescendantsState = useJewishDescendantsState();

  const handlePartialUpdateUserDetail = useCallback(
    (values: any) => dispatch(UserActions.partialUpdateUser.request({ id: currentUser.id, entity: values })),
    [dispatch, currentUser],
  );

  useEffect(() => {
    if (userIsSuccessfullyUpdated) onNext();
  }, [userIsSuccessfullyUpdated, onNext]);

  return (
    <UserInfoView
      user={currentUser}
      jewishDescendants={jewishDescendantsState.entities}
      onSubmit={handlePartialUpdateUserDetail}
    />
  );
};

export default memo(UserInfoContainer);
