import React from 'react';
import { FieldProps } from 'formik';
import { DatePickerProps } from '@mui/x-date-pickers';
import { useTranslation } from 'i18n';
import useTextFieldProps from './useTextFieldProps';

interface IProps extends FieldProps, Omit<DatePickerProps<any, any>, 'name' | 'value' | 'label' | 'error' | 'onError'> {
  DatePicker: React.ElementType;
}

const FieldToDatePicker: React.FC<IProps> = (props) => {
  const { DatePicker, ...rest } = props;

  const [t] = useTranslation();
  const fieldProps = useTextFieldProps(rest, t);

  const handleChange = (value: any, keyboardInputValue: string) => {
    let resultValue = value;
    const { form } = props;
    if (!keyboardInputValue) {
      const date = new Date(value);
      date.setHours(0, 0, 0);
      resultValue = date;
      form.setFieldTouched(fieldProps.name, true, true);
    }

    form.setFieldValue(fieldProps.name, resultValue);
    form.validateField(fieldProps.name);
  };

  return <DatePicker {...fieldProps} onChange={handleChange} />;
};

export default FieldToDatePicker;
