import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CustomDivider from './CustomDivider/CustomDivider';
import CustomChip from './CustomChip/CustomChip';

type Item = {
  id: number;
  parent: number;
  title: string;
};

type Category = {
  id: number;
  title: string;
};

interface LeftSidebarProps {
  categories: Category[];
  items: Item[];
  disabled?: boolean;
  onToggle: (id: number) => void;
  selects: { id: number }[];
}

const useStyles = makeStyles()(() => ({
  interestChip: {
    '&.MuiChip-outlinedDefault': {
      backgroundColor: 'white',
      borderColor: '#CAD1DE',
      color: '#383C44 !important',
    },
  },
}));

const DividerChips: React.FC<LeftSidebarProps> = ({
  categories = [],
  items = [],
  disabled,
  onToggle,
  selects = [],
}) => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={5}>
      {categories.map((category) => (
        <Grid item key={category.id} xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomDivider>
                <Typography variant="h6" noWrap>
                  {category.title}
                </Typography>
              </CustomDivider>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {items
                  .filter((item) => item.parent === category.id)
                  .map(({ id, title }) => {
                    const selected = selects.some((item: any) => item.id === id);
                    const handleToggleInterests = () => {
                      onToggle(id);
                    };
                    return (
                      <Grid item key={id}>
                        <CustomChip
                          className={classes.interestChip}
                          isOpaque
                          textTransform="capitalize"
                          label={title}
                          variant="outlined"
                          color={!selected ? 'default' : 'primary'}
                          disabled={disabled}
                          onClick={handleToggleInterests}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default DividerChips;
