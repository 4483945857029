import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';
import Typography from '@mui/material/Typography';

type Props = TextFieldProps & {
  defaultPreviewText?: string;
  previewText?: string;
  preview?: boolean;
  PreviewComponent?: React.ElementType;
};

const useInputStyles = makeStyles<void, 'root'>()((_theme, _params, classes) => ({
  root: {
    paddingRight: 0,
    backgroundColor: 'unset',
  },
  input: {
    padding: 0,
    minHeight: 24,
    height: 'unset',
    wordWrap: 'break-word',
  },
  notchedOutline: {
    [`.${classes.root}:not(.focused) &`]: {
      borderColor: 'transparent !important',
    },
  },
}));

const useLabelStyles = makeStyles()(() => ({
  root: {
    overflow: 'visible !important',
  },
}));

/* eslint-disable react/jsx-no-duplicate-props */
const FieldPreview: React.FC<Props> = ({
  value,
  preview,
  InputLabelProps,
  InputProps,
  inputProps,
  PreviewComponent = Typography,
  defaultPreviewText = '—',
  previewText,
  ...rest
}) => {
  const { classes: inputClasses } = useInputStyles(undefined, {
    props: InputProps,
  });
  const { classes: labelClasses } = useLabelStyles(undefined, {
    props: InputLabelProps,
  });

  // TODO:
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const getInputProps = (): InputBaseComponentProps => ({
    ...(preview && {
      children: previewText || value || defaultPreviewText,
    }),
    ...inputProps,
  });

  return (
    <TextField
      {...rest}
      value={value}
      InputLabelProps={{
        ...(preview && {
          shrink: true,
        }),
        ...InputLabelProps,
        ...(preview && { classes: labelClasses }),
      }}
      InputProps={{
        ...InputProps,
        ...(preview && {
          inputComponent: PreviewComponent as React.ElementType<InputBaseComponentProps>,
          classes: inputClasses,
        }),
      }}
      inputProps={getInputProps()}
    />
  );
};

export default FieldPreview;
