import React from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  form?: React.ReactNode;
  className?: string;
  overflowY?: boolean;
  minHeight?: number | string;
}

interface StyleProps {
  overflowY: boolean;
}

const useStyles = makeStyles<StyleProps>()((theme: Theme, props) => ({
  wrapper: {
    height: '100%',
    ...(props.overflowY && {
      [theme.breakpoints.down('sm')]: {
        overflowY: 'auto',
      },
    }),
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '120%',
    letterSpacing: '-0.02em',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      marginBottom: theme.spacing(1),
    },
  },
  description: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '19.5px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

const AuthContent: React.FC<IProps> = (props) => {
  const { title, description, form, className, overflowY, minHeight, ...rest } = props;
  const { classes, cx } = useStyles(
    { overflowY },
    {
      props,
    },
  );

  return (
    <div className={cx(className, classes.wrapper)} style={{ minHeight }} {...rest}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      {description && (
        <Typography color="textSecondary" className={classes.description}>
          {description}
        </Typography>
      )}
      {form}
    </div>
  );
};

export default AuthContent;
