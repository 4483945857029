import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IconCheck22 from '../svg/icon-check-22px.svg';

const useStyles = makeStyles()((theme) => ({
  container: {
    background: theme.palette.primary.main,
    color: 'white',
    height: '100%',
    padding: theme.spacing(7, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 3),
    },
  },
  logo: {
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1, 0, 0, 3),
    },
  },
}));

interface TextImageLeftContentProps {
  title: string;
  image: any;
  centerTitle?: boolean;
  pdt?: number;
  descriptionList?: string[];
}

const TextImageLeftContent: React.FC<TextImageLeftContentProps> = ({
  title,
  image,
  centerTitle = false,
  descriptionList = [],
  pdt = 0,
}) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ paddingBottom: 12 }}>
            <Typography
              style={{ fontSize: 24, ...(centerTitle && { textAlign: 'center' }), paddingTop: pdt }}
              variant="h4"
            >
              {title}
            </Typography>
          </Grid>
          {descriptionList.length > 0 && (
            <Grid item xs={12}>
              <Grid container spacing={1.5}>
                {descriptionList.map((description) => (
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item style={{ height: 22 }}>
                        <IconCheck22 />
                      </Grid>
                      <Grid item xs>
                        <Typography style={{ marginLeft: 4, fontSize: 13, fontWeight: 500 }}>{description}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} style={{ width: 266, display: 'flex', justifyContent: 'center' }}>
                {image}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TextImageLeftContent;
