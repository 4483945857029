import React, { useMemo } from 'react';

import { useCountries } from 'hooks';

import RenderComponent from 'components/common/RenderComponent';
import PhoneField, { PhoneFieldProps } from 'components/common/PhoneField';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'store/selectors';

const BUILD_ENV = process.env.NEXT_PUBLIC_BUILD_ENV;
const isProductionEnv = () => BUILD_ENV === 'production';

const PhoneFieldContainer: React.FC<PhoneFieldProps> = ({ ...props }) => {
  const { countries, isCountriesFetching } = useCountries();
  const currentUser = useSelector(AuthSelectors.currentUser);

  const [onlyCountries, localization] = useMemo(() => {
    const onlyCountries = [] as any[];
    const localization = {} as any;
    [
      ...countries,
      ...(isProductionEnv() && !currentUser?.email?.startsWith('nastya.shevchenko')
        ? []
        : [
            {
              code: 'UA',
              name: 'Ukraine',
            },
          ]),
    ].forEach(({ code, name }) => {
      const lowCaseCode = code.toLowerCase();
      onlyCountries.push(lowCaseCode);
      localization[lowCaseCode] = name;
    });
    return [onlyCountries, localization];
  }, [countries, currentUser?.email]);

  return (
    <RenderComponent
      isLoading={isCountriesFetching}
      smallLoader
      renderContent={() => <PhoneField {...props} onlyCountries={onlyCountries} localization={localization} />}
    />
  );
};

export default PhoneFieldContainer;
