import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps extends ChipProps {
  textTransform?: string;
  isOpaque?: boolean;
}

interface StyleProps {
  textTransform?: any;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles<StyleProps>()((_, props: StyleProps) => ({
  label: {
    textTransform: `${props?.textTransform || 'none'} !important`,
  },
}));

const CustomChip: React.FC<IProps> = ({ textTransform = 'none', isOpaque, ...props }: any) => {
  const { classes } = useStyles({ textTransform });
  return (
    <Chip
      {...props}
      classes={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: classes.label,
        disabled: isOpaque && {
          '&$disabled': {
            opacity: 1,
          },
        },
      }}
    />
  );
};

export default CustomChip;
