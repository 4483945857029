import React, { forwardRef } from 'react';
import { Button as MuiButton, ButtonProps, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { ButtonTypeMap } from '@mui/material/Button/Button';

type CustomProps = {
  rounded?: boolean;
  nowrap?: boolean;
  edge?: 'start' | 'end';
};

type Props<D extends React.ElementType = ButtonTypeMap['defaultComponent']> = ButtonProps<D, CustomProps>;

const useStyles = makeStyles<void, 'sizeSmall'>()((theme: Theme, _params, classes) => ({
  sizeSmall: {},
  root: {
    '&.rounded': {
      borderRadius: '64px',
    },
    '&.nowrap': {
      whiteSpace: 'nowrap',
    },
    '&.edge-start': {
      marginLeft: theme.spacing(-1),
      [`& .${classes.sizeSmall}`]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    '&.edge-end': {
      marginRight: theme.spacing(-1),
      [`& .${classes.sizeSmall}`]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    '&.Mui-disabled': {
      color: '#FFF !important',
      backgroundColor: 'rgba(123, 139, 173, 0.4) !important',
    },
  },
}));

const Button = forwardRef(
  <D extends React.ElementType = ButtonTypeMap['defaultComponent']>(props: Props<D>, ref: React.Ref<any>) => {
    const { classes, cx } = useStyles(undefined, {
      props,
    });
    const { rounded, edge, className, nowrap, ...rest } = props;
    return (
      <MuiButton
        {...rest}
        ref={ref}
        classes={classes}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        className={cx({ rounded, [`edge-${edge}`]: edge, nowrap }, className)}
      />
    );
  },
);

(Button as any).muiName = 'Button';

export default Button as ExtendButtonBase<ButtonTypeMap<CustomProps>>;
