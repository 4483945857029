import React from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  form?: React.ReactNode;
  className?: string;
  mt?: number;
  overflowY?: boolean;
  minHeight?: number | string;
}

interface StyleProps {
  overflowY: boolean;
  mt?: number;
}

const useStyles = makeStyles<StyleProps>()((theme: Theme, props) => ({
  wrapper: {
    height: '100%',
    paddingLeft: 56,
    paddingRight: 56,
    ...(props.overflowY
      ? {
          [theme.breakpoints.down('sm')]: {
            overflowY: 'auto',
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 0,
          },
        }
      : {
          [theme.breakpoints.down('sm')]: {
            overflowY: 'false',
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 0,
          },
        }),
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '120%',
    letterSpacing: '-0.02em',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: props?.mt || 75,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 40,
      fontSize: 24,
      marginBottom: theme.spacing(1),
    },
  },
  description: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '19.5px',
    maxWidth: 274,
    marginBottom: theme.spacing(3),
  },
}));

const PassportVerificationContent: React.FC<IProps> = (props) => {
  const { title, description, form, className, overflowY, mt, minHeight, ...rest } = props;
  const { classes, cx } = useStyles(
    { overflowY, mt },
    {
      props,
    },
  );

  return (
    <div className={cx(className, classes.wrapper)} style={{ minHeight }} {...rest}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      {description && (
        <Typography color="textSecondary" className={classes.description}>
          {description}
        </Typography>
      )}
      {form}
    </div>
  );
};

export default PassportVerificationContent;
